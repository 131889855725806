/** @jsx jsx */
import { jsx } from 'theme-ui';
import { graphql } from 'gatsby';

import Layout from '~/components/Layout';
import Metadata from '~/components/Metadata';
import TaxBack from '~/components/Pages/TaxBack';

const TaxBackPage = ({ data: { page } }) => (
  <Layout>
    <Metadata title="taxback" description="" />
    <TaxBack page={page} />
  </Layout>
);

export default TaxBackPage;

export const query = graphql`
  query TaxBackPageQuery {
    page: contentfulPage(slug: { eq: "taxback" }) {
      ...GenericPageFragment
    }
  }
`;
