/** @jsx jsx */
import { jsx, Container, Heading, Text, Box, Flex, Button } from 'theme-ui';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';
import Spacer from '~/components/Generic/Layout/Spacer';
import ArrowDown from '../../../assets/images/icons/arrow_down_full.svg';
import RichTextRenderer from '../../Content/RichText';

const TaxBack = ({ page }) => {
  const luxury = page.sections.filter((section) =>
    section.title.toLowerCase().includes('luxury')
  )[0];
  const states = page.sections.filter((section) =>
    section.title.toLowerCase().includes('states')
  )[0];
  const howItWorks = page.sections.filter((section) =>
    section.title.toLowerCase().includes('works')
  )[0];

  return (
    <Container sx={{ px: '1.5rem', width: '100vw', maxWidth: '78.5rem' }}>
      <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
        <Heading
          as="h1"
          sx={{
            variant: 'text.leadHeading',
            textAlign: 'center',
            maxWidth: '18ch',
            fontSize: ['3rem', '4rem', '4.8rem'],
          }}
        >
          <RichTextRenderer richText={luxury.heading} />
        </Heading>
        <Text
          sx={{
            display: 'block',
            textAlign: 'center',
            fontSize: ['1.3rem', '1.6rem', '2rem'],
            lineHeight: ['1.95rem', '2.5rem', '3rem'],
            width: '100%',
            whiteSpace: 'pre-line',
            p: {
              m: 0,
            },
            a: {
              letterSpacing: 'unset',
              textDecoration: 'underline',
            },
          }}
        >
          <RichTextRenderer richText={luxury.copy} sx={{ width: '100%' }} />
        </Text>
        <Spacer height={['3rem', '3.5rem', '4rem']} />
        <Button as={Link} to={`/taxback${page.hero.ctaPath}`} variant="buttons.ctaHighlight">
          {page.hero.ctaText}
          <ArrowDown />
        </Button>
        <Spacer height={['4rem', '5rem', '6rem']} />
        <Heading
          as="h2"
          sx={{
            variant: 'text.leadHeading',
            textAlign: 'center',
            maxWidth: '18ch',
            fontSize: ['3rem', '4rem', '4.8rem'],
          }}
        >
          <RichTextRenderer richText={states.heading} />
        </Heading>
        <Box
          sx={{
            mb: '2rem',
            mt: '1rem',
            height: ['28.7rem', '30rem', '33.5rem'],
            maxWidth: ['28.7rem', '52.2rem', '78.5rem'],
            minWidth: ['28.7rem', '52.2rem', '78.5rem'],
            backgroundColor: 'lightPurple',
            boxShadow: '3px 3px 0 0 #CF2029',
            border: '1px solid #CF2029',
            borderRadius: '1rem',
            p: ['3rem', '3.5rem', '4rem 5.5rem'],
            '.apostrophe': {
              height: '2rem',
              display: 'block',
              mb: '2rem',
            },
          }}
        >
          <Flex
            sx={{
              fontSize: ['1.3rem', '1.7rem', '2rem'],
              lineHeight: '150%',
              maxHeight: '100%',
              minWidth: '100%',
              ul: {
                maxHeight: '100%',
                width: '100%',
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'column',
                justifyContent: 'space-between',
                m: 0,
                p: 0,
              },
              div: {
                display: 'flex',
                alignItems: 'center',
              },
              li: {
                listStyleType: 'none',
                width: ['50%', '33%'],
              },
              p: {
                m: 0,
              },
            }}
          >
            <RichTextRenderer richText={states.copy} sx={{ width: '100%' }} />
          </Flex>
        </Box>
        <Spacer height={['4rem', '5rem', '6rem']} />
        <Heading
          as="h2"
          id="how-it-will-work"
          sx={{
            variant: 'text.leadHeading',
            textAlign: 'center',
            maxWidth: '18ch',
            fontSize: ['3rem', '4rem', '4.8rem'],
          }}
        >
          <RichTextRenderer richText={howItWorks.heading} />
        </Heading>
        <Flex
          sx={{
            fontSize: ['1.3rem', '1.7rem', '2rem'],
            lineHeight: '150%',
            maxWidth: ['28.7rem', '52.2rem', '78.5rem'],
            minWidth: ['28.7rem', '52.2rem', '78.5rem'],
            maxHeight: '100%',
            ol: {
              '& ::marker': {
                backgroundColor: 'canary',
              },
            },
            li: {
              counterIncrement: 'list-number',
              listStyleType: 'none',
            },
            'li p': {
              position: 'relative',
              my: '3rem',
              ml: [0, 0, '3rem'],
            },
            'li p::before': {
              content: 'counter(list-number)',
              backgroundColor: 'canary',
              position: 'absolute',
              left: ['-4rem', '-4.2rem', '-6.8rem'],
              zIndex: 1,
              display: 'inline-block',
              width: ['2.8rem', '3rem', '3.6rem'],
              height: ['2.8rem', '3rem', '3.6rem'],
              borderRadius: '50%',
              textAlign: 'center',
              pt: ['0.35rem', '0.1rem', '0.2rem'],
              fontSize: ['1.5rem', '1.8rem', '2rem'],
              fontWeight: 700,
              boxSizing: 'border-box',
            },
          }}
        >
          <RichTextRenderer richText={howItWorks.subheading} sx={{ width: '100%' }} />
        </Flex>
      </Flex>
      <Spacer height={['3rem', '3.5rem', '4rem']} />
      <div
        id="aisle-form-submit"
        sx={{
          '.aisle-form-container': {
            display: 'flex',
            position: 'relative',
            mb: '4rem',
            fontFamily: 'heading',
          },
          '.aisle-form-input-text, .aisle-form-input-text:focus': {
            backgroundColor: '#fff',
            color: 'alizarinCrimson',
            borderWidth: '0.1rem',
            borderRadius: '1rem',
            borderColor: 'alizarinCrimson',
            textAlign: 'start',
            height: '5rem',
            boxShadow: 'none',
            mr: '1rem',
            fontFamily: 'heading',
          },
          'input::placeholder': {
            color: 'alizarinCrimson',
          },
          '#aisle-form-container-input-telephone-error, #aisle-form-container-subscribed': {
            textAlign: 'center',
            position: 'absolute',
            left: '50%',
            transform: 'translate(-50%, 0)',
            width: '100%',
            bottom: '-3rem',
            fontFamily: 'heading',
          },
          '#aisle-form-container-input-telephone-error': {
            color: 'alizarinCrimson',
            bottom: '-6rem',
          },
          '._aisle-form-button-style-loading_mpncq_55:after': {
            left: '-6rem',
            borderTopColor: 'alizarinCrimson',
            backgroundImage: 'none !important',
          },
          '.aisle-form-button': {
            display: 'flex',
            flexFlow: 'row nowrap',
            alignItems: 'center',
            justifyContent: 'center',
            textTransform: 'uppercase',
            fontFamily: 'heading',
            fontSize: ['1.2rem', '1.5rem', '1.8rem'],
            height: '5rem',
            fontWeight: 'bold',
            color: 'alizarinCrimson',
            py: '1rem !important',
            px: '2.5rem !important',
            m: 0,
            width: ['12rem', '15rem', '20rem'],
            border: '1px solid transparent',
            borderColor: 'alizarinCrimson',
            backgroundColor: 'canary',
            borderRadius: '1rem',
            transition: 'all 0.3s ease-in-out',
            ':after': {
              content: '" "',
              backgroundImage: `url(${howItWorks.images[0].url})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'contain',
              width: ['2rem', '2rem', '2rem'],
              height: '2rem',
              ml: '1rem',
            },
            ':hover': {
              color: 'alizarinCrimson',
              borderColor: 'alizarinCrimson',
              backgroundColor: 'canary',
              boxShadow: '5px 5px 0 var(--theme-ui-colors-alizarinCrimson)',
            },
          },
        }}
      />
      <Helmet>
        {/* <!--Optional: Inject the default stylesheet--> */}
        <link
          rel="stylesheet"
          href="https://storage.googleapis.com/gotoaisle-assets-store/aisle-form-submit-script/index.css"
        />
        {/* <!--Configure the scripts properties--> */}
        <script>
          {typeof window !== 'undefined' &&
            (function () {
              window.aisleFormConfig = {
                brandReference: 'U2FsdGVkX1+XS4/Cu4i+xx3YYrsBByNoEbnZX8NQb6n03xIa7zWj7gkZPMU+DGWV',
                campaignPhoneNumber: '12707144439',
              };
            })()}
        </script>
        {/* <!--Inject the main script--> */}
        <script
          type="module"
          src="https://storage.googleapis.com/gotoaisle-assets-store/aisle-form-submit-script/index.js"
          onLoad="window.aisleFormLoad(aisleFormConfig)"
        />
      </Helmet>
      <Spacer height={['4rem', '4.5rem', '5rem']} />
    </Container>
  );
};

export default TaxBack;
